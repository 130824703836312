import React from "react";
import { useAtom } from "jotai";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import Footer from "./molecules/footer/Footer";
import SideMenu from "./organism/side-menu/SideMenu";
import ActionDetail from "./pages/action-detail/ActionDetail";
import Action from "./pages/action/Action";
import BadgeList from "./pages/badge-list/BadgeList";
import DiagnosisTop from "./pages/diagnosis-top/DiagnosisTop";
import ErrorHandler from "./pages/error-handler/ErrorHandler";
import FamilySetting from "./pages/family-setting/FamilySetting";
import FirstSetting from "./pages/first-setting/FirstSetting";
import GoalCreate from "./pages/goal-create/GoalCreate";
import Lifeplan from "./pages/lifeplan/Lifeplan";
import Loading, { useLoading } from "./pages/loading/Loading";
import Login from "./pages/login/Login";
import MyPage from "./pages/my-page/MyPage";
import Privacy from "./pages/privacy/Privacy";
import QuestionTop from "./pages/question-top/QuestionTop";
import Result from "./pages/result/Result";
import Serviceterm from "./pages/serviceterm/Serviceterm";
import {
  favoriteModalStateAtom,
  modalStateAtom,
} from "./state/modal/ModalAtom";
import { sideMenuAtom } from "./state/side-menu/SideMenuAtom";
import FamilySettingDetail from "./pages/family-setting-detail/FamilySettingDetail";
import EducationPlan from "./pages/education-plan/EducationPlan";
import ReplanEducation from "./pages/replan-education/ReplanEducation";

const App = () => {
  const [state] = useAtom(sideMenuAtom);
  const [modalState] = useAtom(modalStateAtom);
  const [favoriteModalState] = useAtom(favoriteModalStateAtom);
  const loading = useLoading();

  const containerStyle = () => {
    if (state.isShow) {
      return {
        background: "grey",
      };
    }

    if (modalState.isOpen || favoriteModalState.isOpen) {
      return {
        height: "100vh",
        overflow: "hidden",
      };
    }
    return {};
  };

  return (
    <div className={styles.appContainer} style={containerStyle()}>
      <BrowserRouter basename={"/swan"}>
        <ErrorHandler />
        <Loading />
        {state.isShow ? (
          <SideMenu></SideMenu>
        ) : (
          <Routes>
            <Route index element={<Navigate replace to={"/login"} />} />
            <Route path={"/diagnosis"} element={<DiagnosisTop />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/first-setting"} element={<FirstSetting />}></Route>
            <Route path={"/family-setting"} element={<FamilySetting />}></Route>
            <Route
              path={"/family-setting-detail"}
              element={<FamilySettingDetail />}
            ></Route>
            <Route path={"/education-plan"} element={<EducationPlan />}></Route>
            <Route
              path={"/replan-education"}
              element={<ReplanEducation />}
            ></Route>
            <Route path={"/action"} element={<Action />}></Route>
            <Route path={"/action-detail"} element={<ActionDetail />}></Route>
            <Route path={"/goal-create"} element={<GoalCreate />}></Route>
            <Route path={"/lifeplan"} element={<Lifeplan />}></Route>
            <Route path={"/my-page"} element={<MyPage />}></Route>
            <Route path={"/question"} element={<QuestionTop />}></Route>
            <Route path={"/result"} element={<Result />}></Route>
            <Route path={"/privacy"} element={<Privacy />}></Route>
            <Route path={"/serviceterm"} element={<Serviceterm />}></Route>
            <Route path={"/badge-list"} element={<BadgeList />}></Route>
            <Route path="*" element={<Navigate replace to={"/login"} />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
