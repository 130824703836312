import classes from "./BreadCrumb.module.scss";
import arrow from "../../static/images/gt.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  data: { displayName: string; url?: string }[];
};

const BreadCrumb = (props: Props) => {
  const navigate = useNavigate();
  const isNotLast = (index: number) => {
    return index !== props.data.length - 1;
  };

  const getClass = (index: number) => {
    if (isNotLast(index)) {
      return classes.normal;
    }

    return classes.strong;
  };

  const to = (data: { displayName: string; url?: string }, index: number) => {
    if (data.url == null) {
      return;
    }

    if (!isNotLast(index)) {
      return;
    }

    navigate(data.url);
  };

  return (
    <div className={classes.container}>
      {props.data.map((d, index) => (
        <div key={index}>
          <span className={getClass(index)} onClick={() => to(d, index)}>
            {d.displayName}
          </span>
          {isNotLast(index) && (
            <img className={classes.icon} src={arrow} alt={arrow} />
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumb;
