import React from "react";
import classes from "./DiagnosisTopHeaderForNoChildren.module.scss";
import diagnosisLogo from "../../../static/images/diagnosisHeaderNoChildren.svg";
import image1 from "../../../static/images/resultLogo10.png";
import image2 from "../../../static/images/resultLogo11.png";
import image3 from "../../../static/images/resultLogo12.png";

const DiagnosisTopHeaderForNoChildren = () => {
  return (
    <div className="header">
      {/*<div className={classes.messageBar}>お金との関わり方</div> */}
      <div className={classes.diagnosisLogo}>
        <img src={diagnosisLogo} alt="diagnosisLogo" />
      </div>
      <div className={classes.imageContainer}>
        <div className={classes.contentBox}>
          <img src={image1} alt="image1" className={classes.image} />
          <div className={classes.messageArea}>
            <div>気になったら一直線！</div>
            <div className={classes.mainContainer}>
              <div className={classes.main}>とことん探求者</div>
              <div>タイプ</div>
            </div>
          </div>
        </div>
        <div className={classes.contentBox}>
          <div className={classes.messageArea}>
            <div>頼れるリーダー！</div>
            <div className={classes.mainContainer}>
              <div className={classes.main}>最強バランスの名監督</div>
              <div>タイプ</div>
            </div>
          </div>
          <img src={image2} alt="image2" className={classes.image} />
        </div>
        <div className={classes.contentBox}>
          <img src={image3} alt="image3" className={classes.image} />
          <div className={classes.messageArea}>
            <div>安心感のカタマリ！</div>
            <div className={classes.mainContainer}>
              <div className={classes.main}>ニコニコ守護天使</div>
              <div>タイプ</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisTopHeaderForNoChildren;
