import React from "react";
import classes from "./BadgeCard.module.scss";

interface BadgeCardProps {
  image?: string;
  content?: string;
}

const BadgeCard = (props: BadgeCardProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {props.image && (
          <img src={props.image} alt="" className={classes.images} />
        )}
      </div>
      <div className={classes.content}>{props.content}</div>
    </div>
  );
};

export default BadgeCard;
