import React, { useEffect, useState } from "react";
import classes from "./GoalCreate.module.scss";
import Header from "../../molecules/header/Header";
import Textarea from "../../atoms/textarea/Textarea";
import teacherImage from "../../static/images/teacher-normal.svg";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteUsersGoal,
  postUsersGoal,
  putUsersGoal,
} from "../../api/UsersGoalApi";
import { modalStateAtom } from "../../state/modal/ModalAtom";
import { useAtom } from "jotai";
import ModalTemplate from "../../organism/modal-template/ModalTemplate";

const GoalCreate = () => {
  const location = useLocation();
  const data = location?.state;
  const isCreate = data?.settingType === "create";
  const content = data?.content;
  const goalId = data?.goalId;
  const navigate = useNavigate();
  const postUsersGoalAPI = postUsersGoal();
  const putUsersGoalAPI = putUsersGoal();
  const deleteUsersGoalAPI = deleteUsersGoal();
  const [goalContent, setGoalContent] = useState<string | null>(null);
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const onChange = (value: string) => {
    setGoalContent(value);
  };

  const save = () => {
    if (goalContent == null) {
      return;
    }

    if (isCreate) {
      postUsersGoalAPI.post({
        goalContent: goalContent,
      });
    } else {
      putUsersGoalAPI.put({
        goalId,
        goalContent,
      });
    }
  };

  const deleteGoal = () => {
    if (goalId == null) {
      return;
    }

    deleteUsersGoalAPI.deleteItem({
      goalId,
    });

    setModalState({
      isOpen: false,
    });
  };

  useEffect(() => {
    if (deleteUsersGoalAPI.state?.status === "success") {
      deleteUsersGoalAPI.state.status = null;
      navigate(`/action?type=goal`);
    } else if (postUsersGoalAPI.state?.status === "success") {
      postUsersGoalAPI.state.status = null;
      navigate("/action?type=goal");
    } else if (putUsersGoalAPI.state?.status === "success") {
      putUsersGoalAPI.state.status = null;
      navigate("/action?type=goal");
    }
  }, [
    deleteUsersGoalAPI.state?.status,
    postUsersGoalAPI.state?.status,
    putUsersGoalAPI.state?.status,
  ]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.title}>
        {isCreate ? "夢・目標を新規作成" : "夢・目標を編集"}
      </div>
      <div className={classes.mainArea}>
        <div className={classes.textarea}>
          <Textarea
            label="あなたや家族の「夢」「目標」を1つ教えてください"
            subLabel="1000文字以内"
            placeholder="例）1年に1回は家族全員で海外旅行に行きたい"
            content={content}
            onChange={onChange}
          ></Textarea>
        </div>
        <div>
          <TeacherComment
            image={teacherImage}
            comment={[
              "「いつ」「誰が」「何を」「いくらで」など、なるべく具体的に書きましょう！「夢」「目標」を叶えやすくなりますよ♪",
            ]}
          ></TeacherComment>
        </div>
        <div className={classes.saveButton}>
          <SimpleButton
            label="保存"
            color="orange"
            width="100%"
            disabled={goalContent == null || goalContent.length === 0}
            onClick={save}
          ></SimpleButton>
        </div>
        <div
          className={classes.linkContainer}
          onClick={() => navigate("/action?type=goal")}
        >
          <p>キャンセル</p>
        </div>
        {!isCreate && (
          <div className={classes.deleteButton}>
            <SimpleButton
              label="夢・目標を削除"
              color="redOrange"
              width="100%"
              onClick={() =>
                setModalState({
                  isOpen: true,
                })
              }
            ></SimpleButton>
          </div>
        )}
      </div>
      {modalState.isOpen && (
        <ModalTemplate width={343} height={165}>
          <div className={classes.modalLayout}>
            <div>
              <div className={classes.modalHeader}>
                <div className={classes.modalTitle}>
                  夢・目標を削除しますか？
                </div>
              </div>
              <div className={classes.modalBody}>
                <div className={classes.modalBodyTitle}>
                  この夢・目標を削除してもよろしいですか？
                </div>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <div
                className={classes.modalFooterButton1}
                onClick={() =>
                  setModalState({
                    isOpen: false,
                  })
                }
              >
                キャンセル
              </div>
              <div className={classes.modalFooterButton2} onClick={deleteGoal}>
                削除
              </div>
            </div>
          </div>
        </ModalTemplate>
      )}
      <div className={classes.breadCrumb}>
        <BreadCrumb
          data={[
            { displayName: "みらいいコンシェル", url: "/my-page" },
            { displayName: "アクション", url: "/action?type=goal" },
            { displayName: "夢・目標を新規作成", url: "/goal-create" },
          ]}
        />
      </div>
    </div>
  );
};

export default GoalCreate;
