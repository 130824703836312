import { atom } from "jotai";
import { fetchBase } from "./BaseApi";
import { State } from "./State";

const uri = "api/users/badge";

export type UsersBadge = {
  badgeList: Badge[];
  userId: string;
};

export type Badge = {
  actionId: number;
  actionType: string;
  actionFormatType: string;
  actionFormatCategory: string;
  rankCategory: string;
  actionCategory: string;
  actionSubTitle: string;
  actionMainTitle: string;
  completedDate: string;
  acquiredBadgeType: string;
  badgeImageData: string;
};

export const fetchUsersBadgeAtom = atom<State<UsersBadge> | null>(null);

export const fetchUsersBadge = () => {
  return fetchBase<UsersBadge>(uri, fetchUsersBadgeAtom);
};
