import React, { MouseEventHandler, useEffect, useState } from "react";
import classes from "./LifeplanChart.module.scss";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartSetType } from "../../api/UsersEducationChartApi";
import { addComma, round } from "../../common/utility/number.util";
import { v4 as uuid } from "uuid";
import { RankCategory } from "../../api/UsersActionApi";

export type ChartData = {
  education: number;
  revenue: number;
  expense: number;
  decade: number;
  cash: number;
  asset: number;
};

type Props = {
  chartData: ChartData[] | undefined;
  currentAge: number | undefined;
  hasChildren: boolean | undefined;
  currentRank: RankCategory | undefined;
  clickX?: (p: any) => void;
};
const LifeplanChart = (props: Props) => {
  const id = uuid();
  const [chartSize, setChartSize] = useState<number>();
  const [chartData, setChartData] = useState<ChartData[]>();
  const [domain, setDomain] = useState<[number, number]>();
  const [tickCount, setTickCount] = useState<number>();

  useEffect(() => {
    if (props.chartData == null || props.currentRank == null) {
      return;
    }

    const width = document.getElementById(id)?.clientWidth;
    if (width != null) {
      setChartSize(width - 50);
    }

    const chartSet = props.chartData
      .sort((a, b) => (a.decade < b.decade ? -1 : 1))
      .map((c) => {
        return {
          ...c,
          education: round(c.education / 10000, "floor", 0) ?? 0,
          expense: round(c.expense / 10000, "floor", 0) ?? 0,
          revenue: round(c.revenue / 10000, "floor", 0) ?? 0,
          cash: round(c.cash / 10000, "floor", 0) ?? 0,
          asset: round(c.asset / 10000, "floor", 0) ?? 0,
        };
      });

    // Y軸はMAX＋500万円
    const yMax = Math.max(
      ...chartSet.map((c) =>
        Math.max(
          ...(() => {
            if (props.currentRank === "bronze") {
              return [c.education];
            }
            if (
              props.currentRank === "silver" ||
              props.currentRank === "gold"
            ) {
              return [c.education, c.expense, c.revenue];
            }

            return [c.education, c.expense, c.revenue, c.cash, c.asset];
          })()
        )
      )
    );

    // むずいけど、整数値のうち、第三位で切り上げしたキリの良い数値をy軸に設定
    const digit = -1 * (yMax.toString().length - 2);
    const domainMax = round(yMax, "ceil", digit) ?? 0;
    // 億以上なら1000万単位、以下なら500万単位で線を引く
    const tickUnit = (() => {
      return domainMax / 10;
      // if (domainMax <= 100) {
      //   return 10;
      // }

      // if (domainMax <= 1000) {
      //   return 100;
      // }

      // if (domainMax <= 10000) {
      //   return 1000;
      // }

      // return 2000;
    })();
    setTickCount(domainMax / tickUnit + 1);

    setDomain([0, domainMax]);
    setChartData(chartSet);
  }, [props.chartData, props.currentRank]);

  // Y軸
  const CustomizedYAxisTick = (p: any) => {
    const { x, y, payload } = p;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x="0" y="-2" dy={5} textAnchor="end">
          {addComma(payload.value)}万円
        </text>
      </g>
    );
  };

  const CustomizedXAxisTick = (p: any) => {
    const { x, y, payload } = p;
    const isCurrent = payload.value === props.currentAge;
    const borderRadius = "50%";
    const backgroundColor = isCurrent ? "#008E7E" : "transparent";
    const textColor = isCurrent ? "#FFF" : "#000";

    return (
      <g transform={`translate(${x},${y})`}>
        <rect
          x="-12"
          y="-1"
          width="24"
          height="24"
          fill={backgroundColor}
          rx={borderRadius}
        />{" "}
        <text x="0" y="10" dy={5} textAnchor="middle" fill={textColor}>
          {payload.value}歳
        </text>
      </g>
    );
  };

  const clickXtick = (e: any) => {
    props.clickX?.(e);
  };

  return (
    <div id={id} className={classes.container}>
      <div className={classes.header}>
        {props.currentRank !== "bronze" && (
          <>
            <div className={classes.legend}>
              <div className={`${classes.label} ${classes.revenue}`}></div>
              <div className={classes.title}>収入</div>
            </div>
            <div className={classes.legend}>
              <div className={`${classes.label} ${classes.expense}`}></div>
              <div className={classes.title}>
                {" "}
                {props.hasChildren ? "支出（教育費以外）" : "支出"}
              </div>
            </div>
          </>
        )}

        {props.hasChildren && (
          <div className={classes.legend}>
            <div className={`${classes.label} ${classes.education}`}></div>
            <div className={classes.title}>教育費</div>
          </div>
        )}
      </div>
      {props.currentRank === "platinum" && (
        <div className={classes.header}>
          <div className={classes.legend}>
            <div className={`${classes.labelLine} ${classes.cash}`}></div>
            <div className={classes.title}>現金</div>
          </div>
          <div className={classes.legend}>
            <div className={`${classes.labelLine} ${classes.asset}`}></div>
            <div className={classes.title}>総資産</div>
          </div>
        </div>
      )}
      <ComposedChart
        className={classes.chart}
        data={chartData}
        width={chartSize}
        height={chartSize}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="decade"
          axisLine={false}
          onClick={(e) => clickXtick(e)}
          tick={<CustomizedXAxisTick />}
          allowDataOverflow={true}
        />
        <YAxis
          domain={domain}
          tickCount={tickCount}
          axisLine={false}
          tick={<CustomizedYAxisTick />}
        />
        <Bar
          stackId="expense-chart"
          dataKey="education"
          fill="#F27400"
          barSize={10}
        />
        {props.currentRank !== "bronze" && (
          <>
            <Bar
              stackId="expense-chart"
              dataKey="expense"
              fill="#FABF00"
              barSize={10}
            />
            <Bar
              stackId="revenue-chart"
              dataKey="revenue"
              fill="#34B1A3"
              barSize={10}
            />
          </>
        )}
        {props.currentRank === "platinum" && (
          <>
            <Line dataKey="cash" stroke="#1784E9" dot={false} strokeWidth={3} />
            <Line
              dataKey="asset"
              stroke="#747474"
              dot={false}
              strokeWidth={3}
            />
          </>
        )}
      </ComposedChart>
    </div>
  );
};

export default LifeplanChart;
