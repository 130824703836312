import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import badgeGetImage from "../../static/images/badge-get.png";
import checkIcon from "../../static/images/check-green.svg";
import rankupGetImage from "../../static/images/rankup-get.png";
import classes from "./BadgeResult.module.scss";

type Props = {
  type: "badge" | "rankup" | "badgeInfo";
  data: BadgeResultData;
  close: () => void;
};

type DisplayData = {
  title?: string;
  image: string;
  explanation: string;
  optionalText: string[];
};

export type BadgeResultData = {
  actionId: number;
  actionType?: string;
  badgeExplanation: string;
  badgeImageData: string;
  badgeTitle?: string;
  rankTitle: string;
  rankExplanation: string[];
  rankImageData: string;
};

const BadgeResult = (props: Props) => {
  const [data, setData] = useState<DisplayData>();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.type === "badge") {
      setData({
        title: props.data.badgeTitle,
        image: props.data.badgeImageData,
        explanation: props.data.badgeExplanation,
        optionalText: [],
      });
    } else if (props.type === "rankup") {
      setData({
        title: "おめでとうございます！",
        image: props.data.rankImageData,
        explanation: props.data.rankTitle,
        optionalText: props.data.rankExplanation,
      });
    } else if (props.type === "badgeInfo") {
      setData({
        image: props.data.badgeImageData,
        explanation: props.data.badgeExplanation,
        optionalText: [],
      });
    }
  }, [props]);

  const toLifeplan = () => {
    props.close();
    navigate("/lifeplan");
  };

  const toAction = () => {
    props.close();
    navigate(
      `/action-detail?actionId=${props.data.actionId}&actionType=${props.data.actionType}`
    );
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.card}
        style={{
          minHeight:
            props.type === "badge" || props.type === "badgeInfo"
              ? "330rem"
              : "510rem",
        }}
      >
        {(props.type === "badge" || props.type === "rankup") && (
          <img
            className={classes.ribbonImage}
            src={props.type === "badge" ? badgeGetImage : rankupGetImage}
            alt={props.type === "badge" ? badgeGetImage : rankupGetImage}
          />
        )}
        {props.type !== "badgeInfo" && (
          <div className={classes.title}>{data?.title}</div>
        )}
        <img className={classes.image} src={data?.image} alt={data?.image} />
        <div className={classes.text}>{data?.explanation}</div>
        {data != null && data.optionalText.length > 0 && (
          <div className={classes.optionalContainer}>
            {data.optionalText.map((text) => (
              <div className={classes.line} key={text}>
                <img
                  className={classes.checkIcon}
                  src={checkIcon}
                  alt={checkIcon}
                />
                <span className={classes.optionalText}>{text}</span>
              </div>
            ))}
          </div>
        )}
        {props.type === "rankup" && (
          <div className={classes.lifeplanContainer} onClick={toLifeplan}>
            最新のライフプランを見てみる
          </div>
        )}
        {props.type === "badgeInfo" && (
          <div className={classes.actionContainer} onClick={toAction}>
            もういちど挑戦してみる
          </div>
        )}
        <div className={classes.close} onClick={props.close}>
          閉じる
        </div>
      </div>
    </div>
  );
};

export default BadgeResult;
