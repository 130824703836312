import React, { useEffect, useState } from "react";
import classes from "./DiagnosisTop.module.scss";
import DiagnosisTopContent from "../../organism/diagnosis-top/diagnosis-top-content/DiagnosisTopContent";
import DiagnosisTopHeader from "../../organism/diagnosis-top/diagnosis-top-header/DiagnosisTopHeader";
import DiagnosisTopHeaderNoChildren from "../../organism/diagnosis-top/diagnosis-top-header-for-no-children/DiagnosisTopHeaderForNochildren";
import { fetchUser } from "../../api/UsersApi";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";

function DiagnosisTop() {
  const fetchUserApi = fetchUser();
  const [isChildren, setIsChildren] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      fetchUserApi.fetch();
    }
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data?.[0].isChildren == null) {
      return;
    }
    setIsChildren(fetchUserApi.state?.data?.[0].isChildren);
  }, [fetchUserApi.state?.data]);
  return (
    <div className={classes.container}>
      {isChildren ? <DiagnosisTopHeader /> : <DiagnosisTopHeaderNoChildren />}
      <div className={classes.topWrapper}>
        <DiagnosisTopContent />
      </div>
      <div
        className={classes.linkContainer}
        onClick={() => navigate("/my-page")}
      >
        <p>マイページに戻る</p>
      </div>
      <div className={classes.breadCrumb}>
        {/*<BreadCrumb data={["みらいいコンシェル", "おカネの行動診断"]} />*/}
      </div>
    </div>
  );
}

export default DiagnosisTop;
