import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchUser } from "../../api/UsersApi";
import Accordion from "../../molecules/accordion/Accordion";
import {
  gender,
  getSchoolPlan,
  grade,
  relationships,
  schoolType,
  universityType,
} from "../first-setting/FirstSetting-const";
import DisplayList from "../../molecules/display-list/DisplayList";
import Header from "../../molecules/header/Header";
import PageTitle from "../../atoms/page-title/PageTitle";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import classes from "./EducationPlan.module.scss";
import Avatar from "../../atoms/avatar/Avatar";
import TeacherComment from "../../molecules/teacher-commnet/TeacherComment";
import teacherImage from "../../static/images/teacher-ok.svg";
import { faSchlix } from "@fortawesome/free-brands-svg-icons";

const EducationPlan = () => {
  const [searchParams] = useSearchParams();
  const memberNumber = searchParams.get("id");
  const fetchUserApi = fetchUser();
  const navigate = useNavigate();
  const [displayList, setDisplayList] = useState<
    { label: string; value: string; onClick?: () => void }[]
  >([]);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");

  const [displayCurrentGrade, setDisplayCurrentGrade] = useState<{
    label: string;
    value: string;
  }>();
  const [displaySchoolPlan, setDisplaySchoolPlan] =
    useState<{ label: string; value: string }[]>();

  useEffect(() => {
    if (memberNumber == null) {
      navigate("/family-setting");
    }
    fetchUserApi.fetch();
  }, []);

  useEffect(() => {
    if (fetchUserApi.state?.data == null) {
      return;
    }

    const user = fetchUserApi.state.data[0].familyProfile.find(
      (p) => p.memberNumber == memberNumber
    );

    if (user?.currentGrade == null) {
      return;
    }

    setDisplayCurrentGrade({
      label: "現在の学年",
      value: grade.find((g) => g.value === user?.currentGrade)?.name ?? "",
    });

    const schoolPlan = getSchoolPlan(user.currentGrade);

    setDisplaySchoolPlan(
      schoolPlan.map((s) => {
        const target = Object.entries(user).find((v) => v[0] === s.value);
        if (target != null && target.length > 1) {
          const type =
            target[0] === "university"
              ? universityType.find((t) => t.value === target[1])?.name
              : schoolType.find((t) => t.value === target[1])?.name;

          return { label: s.name, value: type ?? "" };
        }

        return { label: s.name, value: "" };
      })
    );

    setTitle(
      {
        myself: "あなたの情報",
        partner: "パートナー・配偶者の情報",
        children: "子どもの情報",
      }[user?.familyRelationship ?? ""] ?? ""
    );
    setName(user?.familyName ?? "");
  }, [fetchUserApi.state?.status]);

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.body}>
        <div className={classes.avatar}>
          <Avatar
            userName={name}
            width="80rem"
            height="80rem"
            fontSize="20rem"
          />
        </div>
        <div className={classes.educationPlan}>
          <div className={classes.title}>{title}</div>
          <div className={`${classes.row} ${classes.current}`}>
            <div className={`${classes.label} ${classes.current}`}>
              {displayCurrentGrade?.label}
            </div>
            <div className={`${classes.value} ${classes.current}`}>
              {displayCurrentGrade?.value}
            </div>
          </div>
          {displaySchoolPlan?.map((p) => (
            <>
              <div className={classes.row}>
                <div className={classes.label}>{p.label}</div>
                <div className={classes.value}>{p.value}</div>
              </div>
            </>
          ))}
        </div>
        <div className={classes.teacherComment}>
          <TeacherComment image={teacherImage}>
            <div>進学先を変更したいですか？</div>
            <div>
              ライフプランの
              <span
                className={classes.link}
                onClick={() => navigate("/replan-education")}
              >
                教育費の再計算
              </span>
              からできますよ♪
            </div>
          </TeacherComment>
        </div>

        <div className={classes.breadCrumb}>
          <BreadCrumb
            data={[
              { displayName: "みらいいコンシェル", url: "/my-page" },
              { displayName: "家族の基本情報", url: "/family-setting" },
              {
                displayName: "子どもの情報",
                url: `/family-setting-detail?id=${memberNumber}`,
              },
              {
                displayName: "進学先（予定）",
                url: `/education-paln?id=${memberNumber}`,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default EducationPlan;
