import React, { CSSProperties } from "react";
import { useEffect, useState } from "react";
import classes from "./DisplayList.module.scss";
import { v4 as uuidv4 } from "uuid";
import closeIcon from "../../static/images/minus.svg";
import openIcon from "../../static/images/plus.svg";
import arrowIcon from "../../static/images/arrow-right.svg";

type Element = {
  label: string;
  value?: string;
  onClick?: () => void;
};

type Props = {
  list: Element[];
};

const DisplayList = (props: Props) => {
  return (
    <>
      <div className={classes.container}>
        {props.list.map((d) => {
          return (
            <>
              <div className={classes.parentContainer} onClick={d.onClick}>
                <div className={classes.label}>{d.label}</div>
                <div className={classes.value}>{d.value}</div>
                {d.onClick != null ? (
                  <img
                    className={classes.icon}
                    src={arrowIcon}
                    alt={arrowIcon}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default DisplayList;
