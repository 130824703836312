import React, { useEffect, useState } from "react";
import Footer from "../../molecules/footer/Footer";
import styles from "./Login.module.scss";
import TextForm, { TextFormResult } from "../../atoms/text-form/TextForm";
import SimpleButton from "../../atoms/simple-button/SimpleButton";
import { login } from "../../api/LoginApi";
import { useNavigate } from "react-router-dom";
import Header from "../../molecules/header/Header";
import BreadCrumb from "../../molecules/breadcrumb/BreadCrumb";
import iconOpenEye from "../../static/images/iconOpenEye.svg";
import iconCloseEye from "../../static/images/iconCloseEye.svg";

type Form = {
  name: string;
  password: string;
};

const Login = () => {
  const navigator = useNavigate();
  const [formValue, setFormValue] = useState<Form>({
    name: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const loginApi = login();

  const handleInputChange = (args: TextFormResult) => {
    setFormValue((prevValues) => ({ ...prevValues, [args.name]: args.value }));
  };

  const onClick = () => {
    loginApi.post({
      userId: formValue.name,
      password: formValue.password,
    });
  };

  useEffect(() => {
    //if (loginApi.state?.status === "success") {
    //  navigator("/action");
    //}
    if (loginApi.state?.status === "success" && loginApi.state.data) {
      const isFirstLogin = loginApi.state.data[0].isFirstLogin;
      if (isFirstLogin) {
        navigator("/first-setting");
      } else {
        navigator("/action");
      }
    }
  }, [loginApi.state?.data]);

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <Header nonCalledUser={true} />
        <div className={styles.title}>ログイン</div>
        <div className={styles.inputArea}>
          <div className={styles.input}>
            <TextForm
              name="name"
              value={formValue.name}
              color="white"
              label="メールアドレス"
              onChange={handleInputChange}
            ></TextForm>
          </div>
          <div className={styles.input}>
            <TextForm
              name="password"
              value={formValue.password}
              color="white"
              label="パスワード"
              type={showPassword ? "text" : "password"}
              rightIcon={showPassword ? iconOpenEye : iconCloseEye}
              onClickRightButton={() => setShowPassword(!showPassword)}
              onChange={handleInputChange}
            ></TextForm>
          </div>
          <div className={styles.buttonArea}>
            <SimpleButton
              label="ログイン"
              width="100%"
              isRadius={true}
              onClick={onClick}
            ></SimpleButton>
          </div>
        </div>
      </div>
      <BreadCrumb
        data={[
          { displayName: "みらいいコンシェル", url: "/my-page" },
          { displayName: "ログイン", url: "/login" },
        ]}
      />
    </div>
  );
};

export default Login;
